import React from "react"

import Layout from "../components/layout/layout"
// import Container from "../components/layout/container"

const IndexPage = () => (
  <Layout>
    {/* <Container>This page will be forwarded ...</Container> */}
  </Layout>
)

export default IndexPage
